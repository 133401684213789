import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card p-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tree, {
      "allow-drop": _ctx.allowDrop,
      "allow-drag": _ctx.allowDrag,
      data: _ctx.data,
      draggable: "",
      "default-expand-all": "",
      "node-key": "id",
      onNodeDragStart: _ctx.handleDragStart,
      onNodeDragEnter: _ctx.handleDragEnter,
      onNodeDragLeave: _ctx.handleDragLeave,
      onNodeDragOver: _ctx.handleDragOver,
      onNodeDragEnd: _ctx.handleDragEnd,
      onNodeDrop: _ctx.handleDrop,
      onNodeClick: _ctx.handleClick
    }, null, 8, ["allow-drop", "allow-drag", "data", "onNodeDragStart", "onNodeDragEnter", "onNodeDragLeave", "onNodeDragOver", "onNodeDragEnd", "onNodeDrop", "onNodeClick"])
  ]))
}